<template>
  <div class="breathing-timer">
    <h2>Сеанс: {{ sessionDisplayTime }}</h2>


    <div class="circle-container">
      <svg class="progress-ring" :width="circleSize" :height="circleSize" viewBox="0 0 400 400">
        <circle
          class="progress-ring__circle"
          stroke="white"
          stroke-width="20"
          fill="transparent"
          r="180"
          cx="200"
          cy="200"
        />
        <circle
          class="progress-ring__circle"
          :class="isResetting ? 'no-transition' : ''"
          :stroke="isInhale ? 'darkblue' : isHold ? 'green' : 'orange'"
          stroke-width="20"
          fill="transparent"
          r="180"
          cx="200"
          cy="200"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="dashOffset"
          transform="rotate(-90 200 200)"
        />
      </svg>
      <div class="timer-text">
        <h1 >{{ isInhale ? 'Вдох' : isHold ? 'Задержка' : 'Выдох' }}: {{ isInhale ? `${inhaleTime}` : isHold ? `${holdTime}` : `${exhaleTime}` }}</h1>
        <h1 class="big">{{ displayTime }}</h1>
        <h3 v-if="isInhale"> Далее: {{ holdTime > 0 ? `ЗАДЕРЖКА ${holdTime}` : `ВЫДОХ ${exhaleTime}` }}</h3>
        <h3 v-if="isHold"> Далее: ВЫДОХ {{ exhaleTime }}</h3>
        <h3 v-if="isExhale"> Далее: ВДОХ {{ inhaleTime }}</h3>
      </div>
    </div>

    <button :class="isRunning ? 'stop' : 'start'" @click="toggleTimer">{{ isRunning ? 'Стоп' : 'Старт' }}</button>
    <button class="settings" @click="openSettings">Настройки</button>

   <!-- Всплывающее окно с настройками -->
   <div v-if="showSettings" class="settings-modal">
      <div class="settings-content">
        <h2>Настройки</h2>
        <label>
          Время сеанса: {{ settingsSessionTime }} минут
          <input type="range" min="5" max="30" v-model="settingsSessionTime">
        </label>
        <label>
          Длина вдоха: {{ settingsInhaleTime }} секунд
          <input type="range" min="4" max="15" v-model="settingsInhaleTime">
        </label>
        <label>
          Ступень освоения:
          <select v-model="settingsSelectedStep">
            <option v-for="step in steps" :key="step.value" :value="step.value">
              {{ step.label }}
            </option>
          </select>
        </label>

        <label>
          Громкость отсчета: {{ settingsVolume }}
          <input type="range" min="0" max="10" v-model="settingsVolume">
        </label>




        
        <p>Задержка дыхания: {{ settingsHoldTime }} секунд</p>
        <p>Длина выдоха: {{ settingsExhaleTime }} секунд</p>
        <button @click="saveSettings">Сохранить</button>
        <button @click="showSettings = false">Отмена</button>
      </div>
    </div>
  </div>
</template>

<script>

import tickSound from '@/assets/tick_short.mp3';
import tickSound2 from '@/assets/tik-tak.mp3';



export default {
  data() {
    return {
      inhaleTime: 7,
      exhaleTime: 14,
      holdTime: 0,
      sessionTime: 5, // общее время сеанса в секундах (будет изменено на минуты в localStorage)
      timeLeft: 7,
      sessionTimeLeft: 120,
      totalProgress: 0,
      isInhale: true,
      isHold: false,
      isExhale: false,
      isRunning: false,
      timer: null,
      radius: 180,
      circleSize: 390,
      circumference: 2 * Math.PI * 180,
      previousPhaseProgress: 0,
      showSettings: false, // для отображения настроек
      selectedStep: '1:0:1',
      steps: [
        { value: '1:0:1', label: '1:0:1' },
        { value: '1:0:2', label: '1:0:2' },
        { value: '1:1:1', label: '1:1:1' },
        { value: '1:1:2', label: '1:1:2' },
        { value: '1:2:2', label: '1:2:2' },
        { value: '1:3:2', label: '1:3:2' },
        { value: '1:4:2', label: '1:4:2' },
      ],
       // Данные настроек
      volume: 0.5,
      settingsVolume: 5,
      settingsInhaleTime: 7,
      settingsSessionTime: 5,
      settingsSelectedStep: '1:0:1',
      // Флаг для отключения анимации при сбросе
      isResetting: false,
      audio: null,
      audio2: null
    };
  },
  computed: {


// Вычисляемые свойства для настроек
settingsHoldTime() {
      const [inhaleMultiplier, holdMultiplier] = this.settingsSelectedStep
        .split(':')
        .map(Number);
      return holdMultiplier * this.settingsInhaleTime;
    },
    settingsExhaleTime() {
      const [inhaleMultiplier, , exhaleMultiplier] = this.settingsSelectedStep
        .split(':')
        .map(Number);
      return exhaleMultiplier * this.settingsInhaleTime;
    },



    displayTime() {
      let newTimeLeft = this.timeLeft;
      const minutes = Math.floor(newTimeLeft / 60);
      const seconds = newTimeLeft % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    sessionDisplayTime() {
      const minutes = Math.floor(this.sessionTimeLeft / 60);
      const seconds = this.sessionTimeLeft % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },


    

    dashOffset() {
      const totalTimeForPhase = this.isInhale
        ? this.inhaleTime
        : this.isHold
        ? this.holdTime
        : this.exhaleTime;
      const phaseProgress = (this.previousPhaseProgress + (1 - (this.timeLeft-1) / (totalTimeForPhase-1))) ;
      

      return this.circumference * (1 - phaseProgress) ;
     
    },
  },
  methods: {


    openSettings() {
      this.showSettings = true;
      // Инициализируем данные настроек текущими значениями
      this.settingsSessionTime = this.sessionTime;
      this.settingsInhaleTime = this.inhaleTime;
      this.settingsSelectedStep = this.selectedStep;
    },




      startTimer() {
    this.timer = setInterval(() => {

      if (this.sessionTimeLeft > 1) {
        this.sessionTimeLeft -= 1;
      } else {
        this.stopTimer();
        return;
      }

 


  if (this.timeLeft > 1) { // Уменьшаем до 0, чтобы дать время заполнить окружность
    this.timeLeft -= 1;

 // Воспроизводим звук
 this.playTickSound();


  } else {
    this.playTickSound2();
    // Когда время текущей фазы закончилось, переключаем фазу
    if (this.isInhale && this.holdTime > 0) {
      this.previousPhaseProgress += 1;
      this.isInhale = false;
      this.isHold = true;
      this.timeLeft = this.holdTime;
    } else if (this.isInhale && this.holdTime === 0) {
      this.previousPhaseProgress += 1;
      this.isInhale = false;
      this.isHold = false; // Пропускаем задержку
      this.isExhale = true;
      this.timeLeft = this.exhaleTime;
    } else if (this.isHold) {
      this.previousPhaseProgress += 1;
      this.isHold = false;
      this.isExhale = true;
      this.timeLeft = this.exhaleTime;
    } else if (this.isExhale) {
      this.previousPhaseProgress += 1;
      this.isExhale = false;
      this.isInhale = true;
      this.timeLeft = this.inhaleTime;
    }
  }
}, 1000);
},

playTickSound() {
    if (this.audio) {
      // Сбросить время воспроизведения
      this.audio.currentTime = 0;
      this.audio.play().catch(error => {
        // Обработка ошибок воспроизведения (например, пользователь отключил звук)
        console.error('Ошибка воспроизведения звука:', error);
      });
    }
  },


  playTickSound2() {
    if (this.audio2) {
      // Сбросить время воспроизведения
      this.audio2.currentTime = 0;
      this.audio2.play().catch(error => {
        // Обработка ошибок воспроизведения (например, пользователь отключил звук)
        console.error('Ошибка воспроизведения звука:', error);
      });
    }
  },



    stopTimer() {
      clearInterval(this.timer);

      // Отключаем анимацию
      this.isResetting = true;



      this.isRunning = false;
      this.isInhale = true;
      this.isHold = false;
      this.isExhale = false;
      this.timeLeft = this.inhaleTime;
      this.sessionTimeLeft = this.sessionTime * 60; // конвертируем в секунды
      this.previousPhaseProgress = 0;


      setTimeout(() => {
  this.isResetting = false;
}, 50);
      
    },
    toggleTimer() {
      if (this.isRunning) {
        this.stopTimer();
      } else {
        this.stopTimer();
        this.isRunning = true;
        this.startTimer();
      }
    },
    calculateTimes() {
      const [inhaleMultiplier, holdMultiplier, exhaleMultiplier] = this.selectedStep
        .split(':')
        .map(Number);

      this.holdTime = holdMultiplier * this.inhaleTime;
      this.exhaleTime = exhaleMultiplier * this.inhaleTime;
     // console.log(exhaleMultiplier+ ' - '+ this.inhaleTime+ ' ====  '+this.exhaleTime);
      // Сохраняем в localStorage
      localStorage.setItem('sessionTime', this.sessionTime);
      localStorage.setItem('inhaleTime', this.inhaleTime);
      localStorage.setItem('exhaleTime', this.exhaleTime);
      localStorage.setItem('holdTime', this.holdTime);

      localStorage.setItem('volume', this.volume);

      localStorage.setItem('selectedStep', this.selectedStep);
      

      
    },
    saveSettings() {
      // Применяем настройки
      this.sessionTime = this.settingsSessionTime;
      this.inhaleTime = this.settingsInhaleTime;
      this.selectedStep = this.settingsSelectedStep;

      this.volume = this.settingsVolume/10;
      this.audio.volume = this.volume;
      this.calculateTimes();



      this.showSettings = false;
      this.stopTimer();
    },
    loadSettings() {
      this.sessionTime = Number(localStorage.getItem('sessionTime')) || this.sessionTime;
      this.inhaleTime = Number(localStorage.getItem('inhaleTime')) || this.inhaleTime;
      this.holdTime = Number(localStorage.getItem('holdTime')) || this.holdTime;
      this.exhaleTime = Number(localStorage.getItem('exhaleTime')) || this.exhaleTime;
      this.volume = Number(localStorage.getItem('volume')) || this.volume;

      this.selectedStep = Number(localStorage.getItem('selectedStep')) || this.selectedStep;

      this.audio.volume = this.volume;

      
      this.sessionTimeLeft = this.sessionTime * 60; // конвертируем в секунды
    },
  },


  created() {
    // Инициализируем аудио при создании компонента
    
    this.audio = new Audio(tickSound);
    this.audio.volume = this.volume; // Настройте громкость по желанию (0.0 - 1.0)


    this.audio2 = new Audio(tickSound2);
    this.audio2.volume = this.volume; // Настройте громкость по желанию (0.0 - 1.0)

  },



  mounted() {



    this.loadSettings();
    this.stopTimer();



  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style >

body {
  background-color: #76c192;
}






.breathing-timer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-ring__circle {
  transition: stroke-dashoffset 1s linear;
}

.timer-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

button.stop, button.start, .settings {
  margin-top: 20px;
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button.stop {
  background-color: red;
  font-size: 2em;
}

button.start {
  background-color: green;
  font-size: 2em;
}

button.settings {
  background-color: blue;
}

.settings-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.settings-content {
  display: flex;
  flex-direction: column;
}

input[type="range"] {
  width: 100%;
}

select {
  width: 100%;
  padding: 5px;
}

button {
  margin-top: 10px;
}

.no-transition {
  transition: none;
}


.big{
  font-size: 3em;
}


</style>
